
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import MainBanner from "@/components/MainBanner.vue";
import Triptych from "@/components/Triptych.vue";
import TitleText from "@/components/TitleText.vue";
import IconList from "@/components/IconList.vue";
import BrandShowcase from "@/components/BrandShowcase.vue";
import CardShowcase from "@/components/CardShowcase.vue";
import PageBreak from "@/components/PageBreak.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    Triptych,
    TitleText,
    CardShowcase,
    IconList,
    BrandShowcase,
    PageBreak
  }
})
export default class Home extends Vue {
  private banner = {
    heading: "Our focus is on helping businesses and their customers ",
    emphasis: "thrive.",
    text:
      "We are a software development business who build high performing teams and develop successful software applications.",
    text1: "Tech, Talent, Together",
    action: {
      text: "Discuss your project",
      action: "/get-in-touch#contact"
    },
    image: require("@/assets/banner-images/home.png")
  };

  private titleText1 = {
    title:
      "We help organisations thrive by bringing people and technology together.",
    subTitle:
      "Our process means we work together with our customers to gain a deep understanding. We are committed to creating the right blend of our tech and talent for each customer.",
    decorator: [
      {
        image: require("@/assets/page-decorators/green-pill.png"),
        type: "green-pill",
        top: "450px",
        right: "-1300px"
      }
    ],
    customClass: "mt-md",
    buttonText: "Learn about our process",
    buttonLink: "/about",
    buttonLabel: "Learn about our process"
  };

  private triptych = {
    customClass: "my-md",
    type: "advanced",
    element: [
      {
        title: "tech.",
        icon: require("@/assets/tech.png"),
        text: `
          <p>
          Tech that’s made for you. <span class="emphasise"><strong>It’s custom built. It’s what you need.</strong></span>
          </p>
          <p>
          How do we do it? We use AWS + Agile and DevOps principles.
          </p>
          <p>
          From <span class=emphasise><strong>making</strong></span> money, <span class=emphasise><strong>saving</strong></span> money, creating <span class=emphasise><strong>efficiencies</strong></span>,
          <br/><span class=emphasise><strong>integrating</strong></span> systems, <span class=emphasise><strong>solving problems</strong></span> we’ve got the tech for that.
          <br/>And anything else in between.
          </p>`
      },
      {
        title: "talent.",
        icon: require("@/assets/talent.png"),
        text: `
          <p>
            Talent that’s trusted. <span class=emphasise><strong>We’ve delivered big important projects.</strong></span> Again, and again and again.
          </p>
          <p>
            We’re your project management 101. Projects come and go. Bring us in when you need the expertise.
          </p>
          <p>
            We can sit with your internal teams or in our HQ. <span class=emphasise><strong>We’re good at both.</strong></span>
          </p>
          <p>
            We work with other suppliers. <span class=emphasise><strong>Onshore</strong></span> and <span class=emphasise><strong>offshore.</strong></span> Whatever the situation, <span class="emphasise"><strong>we can adapt.</strong></span>
          </p>
        `
      },
      {
        title: "together.",
        icon: require("@/assets/together.png"),
        text: `
          <p>
            Our <span class=emphasise><strong>process.</strong></span> We created it, so we can deliver against your goals. <span class=emphasise><strong>Your agenda is our agenda.</strong></span>
          </p>
          <p>
            It’s where our tech and talent offering come together. The thinkers and the doers.
          </p>
          <p>
            With you; <span class=emphasise><strong>together</strong></span> we customise the right blend of tech and talent. No two customers are the same. You get what you need.
          </p>
        `
      }
    ]
  };

  private titleText2 = {
    customClass: "background--grey pt-md",
    title:
      "Experienced and Agile. Trusted by some of the world’s biggest brands.",
    subTitle:
      "We are chosen by IT and Business leaders from some of the world’s largest brands to enable their own teams to consistently deliver faster and increase quality.",
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble1.png"),
        type: "bubble-one",
        top: "-100px",
        right: "1200px"
      },
      {
        image: require("@/assets/page-decorators/yellow-bubble.png"),
        type: "bubble-two behind d-none d-md-block",
        top: "-260px",
        right: "-40px"
      },
      {
        image: require("@/assets/page-decorators/yellow-bubble.png"),
        type: "bubble-three behind d-md-none",
        top: "-260px",
        right: "-60px"
      }
    ]
  };

  private brandShowcase = {
    customClass: "background--grey py-md",
    brandItems: [
      {
        name: "Atom Bank",
        logo: require("@/assets/brand-logos/atom.png")
      },
      {
        name: "Avios",
        logo: require("@/assets/brand-logos/avios.png")
      },
      {
        name: "Beyond Housing",
        logo: require("@/assets/brand-logos/beyond_housing.png")
      },
      {
        name: "British Airways",
        logo: require("@/assets/brand-logos/british-airways.png")
      },
      {
        name: "British Airways Holidays",
        logo: require("@/assets/brand-logos/british-airways-holidays.png")
      },
      {
        name: "Geoteric",
        logo: require("@/assets/brand-logos/geoteric.png")
      },
      {
        name: "Green Bean by NRG",
        logo: require("@/assets/brand-logos/green-bean.png")
      },
      {
        name: "HC One",
        logo: require("@/assets/brand-logos/hc-one.png")
      },
      {
        name: "IAG",
        logo: require("@/assets/brand-logos/iag.png")
      },
      {
        name: "Ikea",
        logo: require("@/assets/brand-logos/ikea.png")
      },
      {
        name: "MWW",
        logo: require("@/assets/brand-logos/mww.png")
      },
      {
        name: "Northumbrian Water",
        logo: require("@/assets/brand-logos/nwg.png")
      },
      {
        name: "Tharsus",
        logo: require("@/assets/brand-logos/tharsus.png")
      },
      {
        name: "Workcast",
        logo: require("@/assets/brand-logos/workcast.png")
      }
    ]
  };

  private titleText3 = {
    title: "Why Leighton",
    subTitle: "Our customers tell us they come back because:",
    customClass: "mt-md",
    decorator: [
      {
        image: require("@/assets/page-decorators/leighton-pills.png"),
        type: "leighton-pills",
        top: "-285px",
        right: "-500px"
      }
    ]
  };

  private titleText4 = {
    title: "Our Values",
    subTitle:
      "We’re go-getters and big thinkers and we work hard to make your vision a reality. Our values underpin everything we do and how we act. Without them we can’t achieve your dreams.",
    buttonText: "Find out more",
    buttonLink: "/about",
    buttonLabel: "Find out more - Our Values",
    customClass: "mt-md",
    decorator: [
      {
        type: "yellow-pill d-none d-md-block",
        image: require("@/assets/page-decorators/yellow-pill.png"),
        right: "1120px",
        top: "-170px"
      },
      {
        type: "yellow-pill d-md-none",
        image: require("@/assets/page-decorators/yellow-pill.png"),
        right: "500px",
        top: "-750px",
        height: "260px"
      },
      {
        image: require("@/assets/page-decorators/bubble2.png"),
        type: "bubble-two d-none d-md-block",
        top: "-400px",
        right: "-120px"
      }
    ]
  };

  private whyLeighton = {
    customClass: "half-width",
    listItems: [
      {
        icon: require("@/assets/gradient-icons/gradient-trust.png"),
        title: "Trusted Partner",
        hideTitle: true,
        textContent: `
          <strong>They gain a trusted partner who offers support and helps them continually improve</strong>
        `
      },
      {
        icon: require("@/assets/gradient-icons/gradient-quality.png"),
        title: "Quality solutions",
        hideTitle: true,
        textContent: `
          <strong>They receive quality solutions.<br />On time and on budget.</strong>
        `
      },
      {
        icon: require("@/assets/gradient-icons/gradient-target.png"),
        title: "Business improvement",
        hideTitle: true,
        textContent: `
          <strong>They see real business improvement.<br />They reach their goals and thrive.</strong>
        `
      }
    ]
  };

  private cards = {
    customClass: "my-md",
    showcaseTypeMobile: "single-scroller",
    showcaseTypeDesktop: "single-scroller",
    icons: true,
    headingTag: "h4",
    squareTiles: false,
    values: [
      {
        icon: require("@/assets/values-icons/value1.png"),
        title: "Our curiosity inspires the amazing",
        text:
          "Our curiosity is boundless, we ask lots of questions and are open minded and we love exploring new ideas. We aren’t afraid of failure and love to push at the boundaries of the possible as we seek positive change."
      },
      {
        icon: require("@/assets/values-icons/value2.png"),
        title: "Passionate about what we do, proud of what we achieve",
        text:
          "Ok, so the first thing you need to know is we love what we do and we never settle for second best. We always take ownership of our actions and reflect on the work that we have done so we are even better next time."
      },
      {
        icon: require("@/assets/values-icons/value3.png"),
        title: "We act with honesty and truth, always!",
        text:
          "We do what we say we are going to do, when we say we will do it – always! We use good judgement and do the “right thing”."
      },
      {
        icon: require("@/assets/values-icons/value4.png"),
        title:
          "We nurture our amazing people to be the best version of themselves",
        text:
          "We never stop learning and constantly want to improve. At Leighton, everyone is a teacher and a student. That is why our customers keep coming back for more."
      },
      {
        icon: require("@/assets/values-icons/value5.png"),
        title:
          "We collaborate to bring our purpose to life in a caring and supportive way",
        text:
          "So, we admit it, we are different to everyone else. We really care about our customers and each other. We want to make a difference, we will deliver, we value you and will support you in the pursuit of your goals."
      }
    ]
  };
}
